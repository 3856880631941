// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require("../../package.json").version,

  firebase: {
    apiKey: "AIzaSyAFiDY3iSn55HPvRgtqPO49ou6PIVvgaHk",
    authDomain: "hap-service-ticket.firebaseapp.com",
    projectId: "hap-service-ticket",
    storageBucket: "hap-service-ticket.appspot.com",
    messagingSenderId: "466856615006",
    appId: "1:466856615006:web:0e0fb7f6989616302328cf",
    measurementId: "G-63PDS7WZVX",
  },

  //LIVE

   apiUrl: "https://service.hap.in/hapadmin", //Live Admin url
   ticketUrl: "https://service.hap.in/hapticket", //Live Ticket url
   schedulerUrl: "https://service.hap.in/hapscheduler", //Live SchedulerUrl
   gSignInLabel: false,
   production: true,

  //DEV old

 // apiUrl: "https://tcms.hap.in/hapadmin",
/*  ticketUrl: "https://tcms.hap.in/hapticket",
   schedulerUrl: "https://tcms.hap.in/hapscheduler",
 // schedulerUrl: "https://service.hap.in/hapscheduler", //Live SchedulerUrl told by Praveen
  gSignInLabel: true,
  production: false,*/

  //UAT

  // apiUrl: "https://uatservice.hap.in/hapadmin",
  // ticketUrl: "https://uatservice.hap.in/hapticket",
  // gSignInLabel: true,
  // production: false,

  roles: {
    superAdmin: "ROLE_SUPER_ADMIN",
    customer: "ROLE_PROFFESSIONAL_CUSTOMER",
    user: "ROLE_PROFFESIONAL_USER",
    eagle: "ROLE_EAGLE",
    admin: "ROLE_ADMIN",
    moduleLead: "ROLE_MODULE_LEAD",
    cam: "ROLE_CAM",
    msp: "ROLE_MSP",
    hub: "ROLE_HUB_INCHARGE",
    inCharge: "ROLE_EKO_HMB",
    hub_employee: "ROLE_HUB_EMPLOYEE",
    msp_teamLeader: "ROLE_MSP_TEAM_LEADER",
    msp_teamLeader_admin: "ROLE_MSP_ADMIN",
    msp_bpo_admin: "ROLE_BPO_MSP_ADMIN",
  },
  gSigin: {
    /*client_id:"1079319933825-4vvlt1f04bo4g9l133j4bqfpkevlg4sq.apps.googleusercontent.com",
        cookiepolicy: "single_host_origin",
        scope: "profile email",*/
    client_id:
      "186073076655-u48mnp2mh8f3af9fbsh5ee544ct0t7h5.apps.googleusercontent.com",
    plugin_name: "login",
    cookiepolicy: "single_host_origin",
    scope: "profile email",
    // ux_mode: 'redirect',
    // redirect_uri: 'http://localhost:4200'

    // 766548068064-idgl8g85lu1b7l53pjmpn56v49f2hdu3.apps.googleusercontent.com
    //  1079319933825-4vvlt1f04bo4g9l133j4bqfpkevlg4sq.apps.googleusercontent.com
    //503171036709-kjurik5mlmmi2ajer1d427s8erspvd2s.apps.googleusercontent.com
  },
};

/*/


 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 *
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { EmailTicketViewComponent } from "./authentication/emailTicketView/emailTicketView.component";
import { SigninSuperadminComponent } from "./authentication/signin-superadmin/signin-superadmin.component";

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },

      {
        path: "super-login",
        pathMatch: "full",
        component: SigninSuperadminComponent,
      },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "country-master",
        loadChildren: () =>
          import("./country-master/country-master.module").then(
            (m) => m.CountrymasterModule
          ),
      },
      {
        path: "region",
        loadChildren: () =>
          import("./region-master/region-master.module").then(
            (m) => m.RegionmasterModule
          ),
      },
      {
        path: "currency-master",
        loadChildren: () =>
          import("./currency-master/currency-master.module").then(
            (m) => m.CurrencymasterModule
          ),
      },
      {
        path: "fuel-claim",
        loadChildren: () =>
          import("./fuel-claim/fuel-claim.module").then(
            (m) => m.FuelClaimModule
          ),
      },
      {
        path: "plant-master",
        loadChildren: () =>
          import("./plantmaster/plant-master.module").then(
            (m) => m.PlantmasterModule
          ),
      },
      {
        path: "hub-master",
        loadChildren: () =>
          import("./hub-master/hub-master.module").then(
            (m) => m.HubmasterModule
          ),
      },
      {
        path: "hmb-incharge",
        loadChildren: () =>
          import("./hmb-incharge/hmb-incharge.module").then(
            (m) => m.HmbinchargeModule
          ),
      },
      {
        path: "hmb-master",
        loadChildren: () =>
          import("./hmb-master/hmb-master.module").then(
            (m) => m.HmbmasterModule
          ),
      },
      {
        path: "buisness-type",
        loadChildren: () =>
          import("./business-type/buisness-type.module").then(
            (m) => m.BuisnesstypeModule
          ),
      },
      {
        path: "asset-category",
        loadChildren: () =>
          import("./asset-catagory/asset-catagory.module").then(
            (m) => m.AssetCatagoryModule
          ),
      },
      {
        path: "asset-sub-category",
        loadChildren: () =>
          import("./asset-sub-category/asset-sub-category.module").then(
            (m) => m.AssetsubcatModule
          ),
      },
      {
        path: "eko-master",
        loadChildren: () =>
          import("./eko-master/eko-master.module").then(
            (m) => m.EkomasterModule
          ),
      },
      {
        path: "complaint-nature",
        loadChildren: () =>
          import("./compliant-nature/compliant-nature.module").then(
            (m) => m.CompliantnatureModule
          ),
      },
      {
        path: "msp-master",
        loadChildren: () =>
          import("./msp-master/msp-master.module").then(
            (m) => m.MspMasterModule
          ),
      },
      {
        path: "Maintenance-Hierarchy",
        loadChildren: () =>
          import("./maintain-hiearachy/maintain-hiearachy.module").then(
            (m) => m.MaintainHiearachyModule
          ),
      },
      {
        path: "msp-vendor-master",
        loadChildren: () =>
          import("./msp-employee-master/msp-employee-master.module").then(
            (m) => m.MspEmployeeMasterModule
          ),
      },

      {
        path: "msp-hmb-mapping",
        loadChildren: () =>
          import("./msp-hmb-mapping/msp-hmb-mapping.module").then(
            (m) => m.MspHmbMappingModule
          ),
      },
      {
        path: "msp-outlet-mapping",
        loadChildren: () =>
          import("./msp-outlet-mapping/msp-outlet-mapping.module").then(
            (m) => m.MspOutletMappingModule
          ),
      },
      {
        path: "material-master",
        loadChildren: () =>
          import("./material-master/material-master.module").then(
            (m) => m.MaterialMasterModule
          ),
      },
      {
        path: "customer-sales-hierarchy",
        loadChildren: () =>
          import(
            "./customer-sales-hierarchy/customer-sales-hierarchy.module"
          ).then((m) => m.CustomerSalesHierarchyModule),
      },

      {
        path: "outlet-master",
        loadChildren: () =>
          import("./outlet-master/outlet-master.module").then(
            (m) => m.OutletMasterModule
          ),
      },
      {
        path: "daily-preventive",
        loadChildren: () =>
          import("./daily-preventive/daily-preventive.module").then(
            (m) => m.DailyPreventiveModule
          ),
      },
      {
        path: "daily-part",
        loadChildren: () =>
          import("./daily-part/daily-part.module").then(
            (m) => m.DailyPartModule
          ),
      },
      {
        path: "daily-scrap",
        loadChildren: () =>
          import("./daily-scrap/daily-scrap.module").then(
            (m) => m.DailyScrapModule
          ),
      },
      {
        path: "daily-fuel-claim",
        loadChildren: () =>
          import("./daily-fuel-claim/daily-fuel-claim.module").then(
            (m) => m.DailyFuelClaimModule
          ),
      },
      {
        path: "daily-hub-ticket",
        loadChildren: () =>
          import("./daily-hub-ticket/daily-hub-ticket.module").then(
            (m) => m.DailyHubTicketModule
          ),
      },
      {
        path: "daily-tickets",
        loadChildren: () =>
          import("./daily-tickets/daily-tickets.module").then(
            (m) => m.DailyTicketsModule
          ),
      },

      {
        path: "ibaco-asset",
        loadChildren: () =>
          import("./ibaco-asset/ibaco-asset.module").then(
            (m) => m.IbacoAssetModule
          ),
      },

      {
        path: "retailer-master",
        loadChildren: () =>
          import("./retailer-master/retailer-master.module").then(
            (m) => m.RetailerMasterModule
          ),
      },
      {
        path: "outlet-asset-master",
        loadChildren: () =>
          import("./outlet-asset-master/outlet-asset-master.module").then(
            (m) => m.OutletAssetMasterModule
          ),
      },
      {
        path: "asset-brand",
        loadChildren: () =>
          import("./asset-brand/asset-brand.module").then(
            (m) => m.AssetBrandModule
          ),
      },
      {
        path: "dispatch-type",
        loadChildren: () =>
          import("./dispatch-type/dispatch-type.module").then(
            (m) => m.DispatchTypeModule
          ),
      },
      {
        path: "brand-vendor",
        loadChildren: () =>
          import("./brand-vendor/brand-vendor.module").then(
            (m) => m.BrandVendorModule
          ),
      },
      {
        path: "location-master",
        loadChildren: () =>
          import("./location-master/location-master.module").then(
            (m) => m.LocationMasterModule
          ),
      },
      {
        path: "asset-capacity",
        loadChildren: () =>
          import("./asset-capacity/asset-capacity.module").then(
            (m) => m.AssetCapacityModule
          ),
      },
      {
        path: "part-master",
        loadChildren: () =>
          import("./part-master/part-master.module").then(
            (m) => m.PartMasterModule
          ),
      },
      {
        path: "preventive-master",
        loadChildren: () =>
          import("./preventive-master/preventive-master.module").then(
            (m) => m.PreventiveMasterModule
          ),
      },
      {
        path: "transport-type",
        loadChildren: () =>
          import("./transport-type/transport-type.module").then(
            (m) => m.TransportTypeModule
          ),
      },
      {
        path: "preventive-action",
        loadChildren: () =>
          import("./preventive-action/preventive-action.module").then(
            (m) => m.PreventiveActionModule
          ),
      },
      {
        path: "partstatus-master",
        loadChildren: () =>
          import("./partstatus-master/partstatus-master.module").then(
            (m) => m.PartstatusMasterModule
          ),
      },
      {
        path: "reason-category",
        loadChildren: () =>
          import("./reason-category/reason-category.module").then(
            (m) => m.ReasonCategoryModule
          ),
      },
      {
        path: "reason-master",
        loadChildren: () =>
          import("./reason-master/reason-master.module").then(
            (m) => m.ReasonMasterModule
          ),
      },
      {
        path: "mode-transport",
        loadChildren: () =>
          import("./mode-transport/mode-transport.module").then(
            (m) => m.ModeTransportModule
          ),
      },
      {
        path: "platform-master",
        loadChildren: () =>
          import("./platform-master/platform-master.module").then(
            (m) => m.PlatformMasterModule
          ),
      },
      {
        path: "vendor-master",
        loadChildren: () =>
          import("./vendor-master/vendor-master.module").then(
            (m) => m.VendorMasterModule
          ),
      },

      {
        path: "main-tile",
        loadChildren: () =>
          import("./main-tile-category/main-tile.module").then(
            (m) => m.MaintilecatModule
          ),
      },
      {
        path: "geo-zone-master",
        loadChildren: () =>
          import("./geo-zone-master/geo-zone-master.module").then(
            (m) => m.GeozoneModule
          ),
      },
      {
        path: "attachment-type",
        loadChildren: () =>
          import("./attachment-type/attachment-type.module").then(
            (m) => m.AttachmenttypeModule
          ),
      },
      {
        path: "carryfwperiod",
        loadChildren: () =>
          import("./carry-forward-period/carry-forward-period.module").then(
            (m) => m.CarryfwperiodModule
          ),
      },
      {
        path: "language",
        loadChildren: () =>
          import("./language/language.module").then((m) => m.LanguageModule),
      },
      {
        path: "genset-price",
        loadChildren: () =>
          import("./genset-price/genset-price.module").then(
            (m) => m.GensetPriceModule
          ),
      },
      {
        path: "genset-mileage",
        loadChildren: () =>
          import("./genset-millege/genset-millege.module").then(
            (m) => m.GensetMillegeModule
          ),
      },
      {
        path: "uom",
        loadChildren: () => import("./uom/uom.module").then((m) => m.UOMModule),
      },
      {
        path: "message-class",
        loadChildren: () =>
          import("./message-class/message-class.module").then(
            (m) => m.MessageclassModule
          ),
      },
      {
        path: "rule-action-screen",
        loadChildren: () =>
          import("./rule-action-screen/rule-action-screen.module").then(
            (m) => m.RulescreenModule
          ),
      },
      {
        path: "rule-action-email",
        loadChildren: () =>
          import("./rule-action-email/rule-action-email.module").then(
            (m) => m.RuleemailModule
          ),
      },
      {
        path: "rule-action-level",
        loadChildren: () =>
          import("./rule-action-level/rule-action-level.module").then(
            (m) => m.RulelevelModule
          ),
      },
      {
        path: "rule-action-mode",
        loadChildren: () =>
          import("./rule-action-mode/rule-action-mode.module").then(
            (m) => m.RulemodeModule
          ),
      },
      {
        path: "rule-action-type",
        loadChildren: () =>
          import("./rule-action-type/rule-action-type.module").then(
            (m) => m.RuletypeModule
          ),
      },
      {
        path: "rule-action-color",
        loadChildren: () =>
          import("./rule-action-color/rule-action-color.module").then(
            (m) => m.RulecolorModule
          ),
      },

      {
        path: "dashboard2",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "menu/:id",
        loadChildren: () =>
          import("./layout/tile-menu/tile-menu.module").then(
            (m) => m.TileMenuModule
          ),
      },

      {
        path: "usermaster",
        loadChildren: () =>
          import("./user-master/user-master.module").then(
            (m) => m.UsermasterModule
          ),
      },
      {
        path: "teamtype",
        loadChildren: () =>
          import("./team-type/team-type.module").then((m) => m.TeamtypeModule),
      },
      {
        path: "ticketsubstatusmaster",
        loadChildren: () =>
          import("./ticket-substatus/ticket-substatus.module").then(
            (m) => m.TicketsubstatusModule
          ),
      },

      {
        path: "department-wise-ticket",
        loadChildren: () =>
          import(
            "./department-wisetc-report/department-wisetc-report.module"
          ).then((m) => m.DepartmentWisetcReportModule),
      },

      {
        path: "projectwiseticketreport",
        loadChildren: () =>
          import(
            "./projectwiseticketreport/projectwiseticketreport.module"
          ).then((m) => m.ProjectwiseticketreportModule),
      },
      {
        path: "hub-report",
        loadChildren: () =>
          import("./hub-report/hub-report.module").then(
            (m) => m.HubReportModule
          ),
      },
      {
        path: "multi-hub-report",
        loadChildren: () =>
          import("./multi-hub-report/multi-hub-report.module").then(
            (m) => m.MultiHubReportModule
          ),
      },
      {
        path: "msp-performance-report",
        loadChildren: () =>
          import("./msp-performance-report/msp-performance-report.module").then(
            (m) => m.MspPerformanceReportModule
          ),
      },

      {
        path: "multi-reports",
        loadChildren: () =>
          import("./multi-reports/multi-reports.module").then(
            (m) => m.MultiReportsModule
          ),
      },

      {
        path: "msp-milksourcingticket",
        loadChildren: () =>
          import("./msp-milksourcingticket/msp-milksourcingticket.module").then(
            (m) => m.MspMilksourcingticketModule
          ),
      },
      {
        path: "gensetfuelclaim-report",
        loadChildren: () =>
          import("./gensetfuelclaim-report/gensetfuelclaim-report.module").then(
            (m) => m.GensetfuelclaimReportModule
          ),
      },
      {
        path: "licensetype",
        loadChildren: () =>
          import("./license-type/license-type.module").then(
            (m) => m.LicensetypeModule
          ),
      },
      {
        path: "licensedeal",
        loadChildren: () =>
          import("./license-deal/license-deal.module").then(
            (m) => m.LicensedealModule
          ),
      },
      {
        path: "departmentmaster",
        loadChildren: () =>
          import("./department-master/depart-master.module").then(
            (m) => m.DepartmentMasterModule
          ),
      },
      {
        path: "deptmodule",
        loadChildren: () =>
          import("./dept-module/dept-module.module").then(
            (m) => m.DepartmentModule
          ),
      },
      {
        path: "maingroup",
        loadChildren: () =>
          import("./main-group/main-group.module").then(
            (m) => m.MaingroupModule
          ),
      },
      {
        path: "ticket",
        loadChildren: () =>
          import("./ticket-new/ticket.module").then((m) => m.TicketModule),
      },

      {
        path: "part-request",
        loadChildren: () =>
          import("./part-request/part-request.module").then(
            (m) => m.PartRequestModule
          ),
      },
      {
        path: "mass-ticket",
        loadChildren: () =>
          import("./mass-ticket-update/mass-ticket-update.module").then(
            (m) => m.MassTicketUpdateModule
          ),
      },
      {
        path: "oem-transcation",
        loadChildren: () =>
          import("./oem-transcation/oem-transcation.module").then(
            (m) => m.OemTranscationModule
          ),
      },
      {
        path: "own-asset-verification",
        loadChildren: () =>
          import("./own-asset-verification/own-asset-verification.module").then(
            (m) => m.OwnAssetVerificationModule
          ),
      },
      {
        path: "scrap",
        loadChildren: () =>
          import("./scrap-ticket/scrap-ticket.module").then(
            (m) => m.ScrapTicketModule
          ),
      },
      {
        path: "cammaster",
        loadChildren: () =>
          import("./cammaster/cammaster.module").then((m) => m.CammasterModule),
      },
      {
        path: "rtm",
        loadChildren: () => import("./rtm/rtm.module").then((m) => m.RtmModule),
      },
      {
        path: "productmaster",
        loadChildren: () =>
          import("./product-master/product-master.module").then(
            (m) => m.ProductmasterModule
          ),
      },
      {
        path: "productmodule",
        loadChildren: () =>
          import("./product-module/product-module.module").then(
            (m) => m.ProductmoduleModule
          ),
      },
      {
        path: "companymaster",
        loadChildren: () =>
          import("./company-master/company-master.module").then(
            (m) => m.CompanyMasterModule
          ),
      },
      {
        path: "tilemaster",
        loadChildren: () =>
          import("./tile-master/tile-master.module").then(
            (m) => m.TilemasterModule
          ),
      },

      {
        path: "umr",
        loadChildren: () => import("./umr/umr.module").then((m) => m.UmrModule),
      },
      {
        path: "holidaymaster",
        loadChildren: () =>
          import("./holiday-master/holiday-master.module").then(
            (m) => m.HolidaymasterModule
          ),
      },

      {
        path: "greatnumber",
        loadChildren: () =>
          import("./greatnumber/greatnumber.module").then(
            (m) => m.GreatNumberModule
          ),
      },
      {
        path: "prioritymaster",
        loadChildren: () =>
          import("./prioritymaster/priority-master.module").then(
            (m) => m.PriorityMasterModule
          ),
      },
      {
        path: "ticketstatusmaster",
        loadChildren: () =>
          import("./ticket-status/ticket-status.module").then(
            (m) => m.TicketstatusModule
          ),
      },
      {
        path: "rolemaster",
        loadChildren: () =>
          import("./role-master/role-master.module").then(
            (m) => m.RolemasterModule
          ),
      },
      {
        path: "roletypemaster",
        loadChildren: () =>
          import("./role-type/role-type.module").then(
            (m) => m.RoletypemasterModule
          ),
      },
      {
        path: "licenseroleassignment",
        loadChildren: () =>
          import(
            "./license-role-assignment/license-role-assignment.module"
          ).then((m) => m.LicroleassModule),
      },
      {
        path: "projectcustgrp",
        loadChildren: () =>
          import("./project-custgrp/project-custgrp.module").then(
            (m) => m.ProjectcustgrpModule
          ),
      },
      {
        path: "projectsubgrp",
        loadChildren: () =>
          import("./project-subgrp/project-subgrp.module").then(
            (m) => m.ProjectsubgrpModule
          ),
      },
      {
        path: "projectsubgrpmapping",
        loadChildren: () =>
          import("./projectsubgrpmapping/projectsubgrpmapping.module").then(
            (m) => m.ProjectsubgrpmappingModule
          ),
      },
      {
        path: "projectsubgrpteam",
        loadChildren: () =>
          import("./projectsubgrpteam/projectsubgrpteam.module").then(
            (m) => m.ProjectsubgrpteamModule
          ),
      },
      {
        path: "projectmaster",
        loadChildren: () =>
          import("./projectmaster/projectmaster.module").then(
            (m) => m.ProjectmasterModule
          ),
      },
      {
        path: "supporttype",
        loadChildren: () =>
          import("./support-type/support-type.module").then(
            (m) => m.SupporttypeModule
          ),
      },

      {
        path: "tickettypeclassification",
        loadChildren: () =>
          import("./ticket-type-classification/ticket-type-clfs.module").then(
            (m) => m.TickettypeclfsModule
          ),
      },
      {
        path: "tickettype",
        loadChildren: () =>
          import("./ticket-type/ticket-type.module").then(
            (m) => m.TickettypeModule
          ),
      },
      {
        path: "projectsubtype",
        loadChildren: () =>
          import("./projectsubtype/projectsubtype.module").then(
            (m) => m.ProjectsubtypeModule
          ),
      },
      {
        path: "projecttype",
        loadChildren: () =>
          import("./project-type/project-type.module").then(
            (m) => m.ProjecttypeModule
          ),
      },
      {
        path: "numberrange",
        loadChildren: () =>
          import("./number-range/number-range.module").then(
            (m) => m.NumberrangeModule
          ),
      },
      {
        path: "ticketnumberrange",
        loadChildren: () =>
          import("./ticketnumberrange/ticketnumberrange.module").then(
            (m) => m.TicketnumberrangeModule
          ),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./charts/charts.module").then((m) => m.ChartsModule),
      },
      {
        path: "preventive-master",
        loadChildren: () =>
          import("./preventive-master/preventive-master.module").then(
            (m) => m.PreventiveMasterModule
          ),
      },
      {
        path: "msp-employee-master",
        loadChildren: () =>
          import("./msp-employee-master/msp-employee-master.module").then(
            (m) => m.MspEmployeeMasterModule
          ),
      },
      {
        path: "part-master",
        loadChildren: () =>
          import("./part-master/part-master.module").then(
            (m) => m.PartMasterModule
          ),
      },
      {
        path: "material-master",
        loadChildren: () =>
          import("./material-master/material-master.module").then(
            (m) => m.MaterialMasterModule
          ),
      },
      {
        path: "manual-milk-collection-report",
        loadChildren: () =>
          import(
            "./manual-milk-collection-report/manual-milk-collection-report.module"
          ).then((m) => m.ManualMilkCollectionReportModule),
      },

      {
        path: "transport-type",
        loadChildren: () =>
          import("./transport-type/transport-type.module").then(
            (m) => m.TransportTypeModule
          ),
      },
      {
        path: "ticketsummary-report",
        loadChildren: () =>
          import("./ticketsummary-report/ticketsummary-report.module").then(
            (m) => m.TicketsummaryReportModule
          ),
      },

      {
        path: "preventive-action",
        loadChildren: () =>
          import("./preventive-action/preventive-action.module").then(
            (m) => m.PreventiveActionModule
          ),
      },
      {
        path: "location-master",
        loadChildren: () =>
          import("./location-master/location-master.module").then(
            (m) => m.LocationMasterModule
          ),
      },
      {
        path: "multi-report",
        loadChildren: () =>
          import("./multi-report/multi-report.module").then(
            (m) => m.MultiReportModule
          ),
      },

      {
        path: "mobileappnotused-incharge",
        loadChildren: () =>
          import(
            "./mobileappnotused-incharge/mobileappnotused-incharge.module"
          ).then((m) => m.MobileappnotusedInchargeModule),
      },
      {
        path: "ticketquartercomparision",
        loadChildren: () =>
          import(
            "./ticketquartercomparision/ticketquartercomparision.module"
          ).then((m) => m.TicketquartercomparisionModule),
      },
      {
        path: "divisionwisereport",
        loadChildren: () =>
          import("./divisionwisereport/divisionwisereport.module").then(
            (m) => m.DivisionwisereportModule
          ),
      },
      {
        path: "overall-ticket",
        loadChildren: () =>
          import("./overall-ticket/overall-ticket.module").then(
            (m) => m.OverallTicketModule
          ),
      },

      {
        path: "fuel-claim",
        loadChildren: () =>
          import("./fuel-claim/fuel-claim.module").then(
            (m) => m.FuelClaimModule
          ),
      },
      {
        path: "parts-stock",
        loadChildren: () =>
          import("./parts-stock/parts-stock.module").then(
            (m) => m.PartsStockModule
          ),
      },
      {
        path: "consumable-asset",
        loadChildren: () =>
          import("./consumable-asset/consumable-asset.module").then(
            (m) => m.ConsumableAssetModule
          ),
      },
      {
        path: "sales-asset",
        loadChildren: () =>
          import("./sales-asset/sales-asset.module").then(
            (m) => m.SalesAssetModule
          ),
      },

      {
        path: "own-asset",
        loadChildren: () =>
          import("./own-asset/own-asset.module").then((m) => m.OwnAssetModule),
      },
      {
        path: "field-force-master",
        loadChildren: () =>
          import(
            "./field-force-staff-hierarchy/field-force-staff-hierarchy.module"
          ).then((m) => m.FieldForceStaffHierarchyModule),
      },
      {
        path: "oem-master",
        loadChildren: () =>
          import("./oem-master/oem-master.module").then(
            (m) => m.OemMasterModule
          ),
      },
      {
        path: "customer-branding-master",
        loadChildren: () =>
          import(
            "./customer-branding-master/customer-branding-master.module"
          ).then((m) => m.CustomerBrandingMasterModule),
      },
      {
        path: "branding-ticket",
        loadChildren: () =>
          import("./branding-ticket/branding-ticket.module").then(
            (m) => m.BrandingTicketModule
          ),
      },
      {
        path: "calibration-report",
        loadChildren: () =>
          import("./calibration-report/calibration-report.module").then(
            (m) => m.CalibrationReportModule
          ),
      },
        {
        path: "asset-warranty-report",
        loadChildren: () =>
          import("./asset-warranty-report/asset-warranty-report.module").then(
            (m) => m.AssetWarrantyReportModule
          ),
      },
      {
        path: "region-wise-vendor",
        loadChildren: () =>
          import("./region-wise-vendor/region-wise-vendor.module").then(
            (m) => m.RegionWiseVendorModule
          ),
      },
      {
        path: "brand-work",
        loadChildren: () =>
          import("./brand-work/brand-work.module").then(
            (m) => m.BrandWorkModule
          ),
      },
      {
        path: "brand-work-type",
        loadChildren: () =>
          import("./brand-work-type/brand-work-type.module").then(
            (m) => m.BrandWorkTypeModule
          ),
      },
      {
        path: "branding-master",
        loadChildren: () =>
          import("./branding-master/branding-master.module").then(
            (m) => m.BrandingMasterModule
          ),
      },
      {
        path: "rtd-map",
        loadChildren: () =>
          import("./rtd-map/rtd-map.module").then((m) => m.RtdMapModule),
      },
      {
        path: "mass-ticket-update",
        loadChildren: () =>
          import("./mass-ticket-update/mass-ticket-update.module").then(
            (m) => m.MassTicketUpdateModule
          ),
      },
      {
        path: "forward-hub",
        loadChildren: () =>
          import("./forward-hub/forward-hub.module").then(
            (m) => m.ForwardHubModule
          ),
      },
      {
        path: "eko-complaint-report",
        loadChildren: () =>
          import("./eko-complaint-report/eko-complaint-report.module").then(
            (m) => m.EkoComplaintReportModule
          ),
      },
      {
        path: "msptoMspallocation",
        loadChildren: () =>
          import("./mspto-mspallocation/mspto-mspallocation.module").then(
            (m) => m.MsptoMspallocationModule
          ),
      },
      {
        path: "preventive-maintenance-tracker",
        loadChildren: () =>
          import(
            "./preventive-maintenance-tracker/preventive-maintenance-tracker.module"
          ).then((m) => m.PreventiveMaintenanceTrackerModule),
      },
      {
        path: "oem-branding-report",
        loadChildren: () =>
          import("./oem-branding-report/oem-branding-report.module").then(
            (m) => m.OemBrandingReportModule
          ),
      },
      {
        path: "oem-report",
        loadChildren: () =>
          import("./oem-report/oem-report.module").then(
            (m) => m.OemReportModule
          ),
      },
      {
        path: "rolewise-division-access",
        loadChildren: () =>
          import(
            "./rolewise-division-access/rolewise-division-access.module"
          ).then((m) => m.RolewiseDivisionAccessModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },

  { path: "emailTicketView", component: EmailTicketViewComponent },

  {
    path: "**",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: Page404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
